import Vue from 'vue'
import VueRouter from 'vue-router';
import MainLayout from '@/layouts/MainLayout';
import Home from '@/views/Home';
import store from '@/store';

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/Login')
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/Register')
  },
  {
    name: 'bind',
    path: '/bind',
    meta: { auth: true },
    component: () => import('@/views/Bind')
  },
  {
    name: 'find',
    path: '/find',
    component: () => import('@/views/Find')
  },
  {
    path: '/',
    name: 'layout',
    component: MainLayout,
    redirect: { name: 'Home' },
    children: [
      {
        name: 'home',
        path: '/',
        component: Home
      },
      {
        name: 'pay',
        path: '/pay',
        meta: { auth: true },
        component: () => import('@/views/Pay')
      },
      {
        name: 'points',
        path: '/points',
        meta: { auth: true },
        component: () => import('@/views/Points')
      },
      
      {
        name: 'payType',
        path: '/payType',
        meta: { auth: true },
        component: () => import('@/views/PayType')
      },
      {
        name: 'recharge',
        path: '/recharge',
        meta: { auth: true },
        component: () => import('@/views/Recharge')
      },
      {
        name: 'messages',
        path: '/messages',
        meta: { auth: true },
        component: () => import('@/views/Messages')
      },
      {
        name: 'joinAgreement',
        path: '/agreement',
        component: () => import('@/views/JoinAgreement')
      },
      {
        name: 'collection',
        path: '/collection',
        meta: { auth: true },
        component: () => import('@/views/Collection')
      },
      {
        name: 'myCoupon',
        path: '/my/coupon',
        meta: { auth: true },
        component: () => import('@/views/MyCoupon')
      },
      {
        name: 'coupon',
        path: '/coupon',
        component: () => import('@/views/Coupon')
      },
      {
        name: 'myOrder',
        path: '/my/order',
        meta: { auth: true },
        component: () => import('@/views/MyOrder')
      },
      {
        name: 'orderDetail',
        path: '/order/detail/:id',
        component: () => import('@/views/MyOrder/Detail/index.vue')
      },
      {
        name: 'wishlist',
        path: '/wishlist',
        meta: { auth: true },
        component: () => import('@/views/Wishlist')
      },
      {
        name: 'myAddress',
        path: '/my/address/:id?/:orderId?',
        component: () => import('@/views/MyAddress')
      },
      {
        name: 'orderRecords',
        path: '/order/records/:commodityIds',
        meta: { auth: true },
        component: () => import('@/views/MyOrder/records')
      },
      {
        name: 'partyEntry',
        path: '/active',
        component: () => import('@/views/PartyEntry')
      },
      {
        name: 'partyDetail',
        path: '/active/detail/:id',
        component: () => import('@/views/PartyEntry/detail')
      },
      {
        name: 'allBrand',
        path: '/brand/all/:name?',
        component: () => import('@/views/AllBrand')
      },
      {
        name: 'allCategory',
        path: '/allcategory',
        component: () => import('@/views/AllCategory')
      },
      {
        name: 'category',
        path: '/category/:name?',
        component: () => import('@/views/Category')
      },
      {
        name: 'allStore',
        path: '/store/all/:name?',
        component: () => import('@/views/Stores/all')
      },
      {
        name: 'cart',
        path: '/cart',
        component: () => import('@/views/Cart')
      },
      {
        name: 'confirmOrder',
        path: '/order/confirm/:id?',
        component: () => import('@/views/ConfirmOrder')
      }, 
      {
        name: 'chosePayment',
        path: '/order/payment/:id?',
        component: () => import('@/views/ChosePayment')
      },
      {
        name: 'brandList',
        path: '/brand/list/:id?',
        component: () => import('@/views/BrandList')
      },
      {
        name: 'goodsDetail',
        path: '/goods/detail/:commodityId',
        component: () => import('@/views/ProdDetail')
      },
      {
        name: 'applySeller',
        path: '/seller/apply',
        meta: { auth: true },
        component: () => import('@/views/ApplySeller')
      },
      {
        name: 'applySellerAudit',
        path: '/seller/audit',
        meta: { auth: true },
        component: () => import('@/views/ApplySellerAudit')
      },
      {
        name: 'qa',
        path: '/qa',
        component: () => import('@/views/QA')
      },
      {
        name: 'privateTerm',
        path: '/privateTerm',
        component: () => import('@/views/PrivateTerm')
      },
      {
        name: 'userxieyi',
        path: '/userxieyi',
        component: () => import('@/views/Userxieyi')
      },
      {
        name: 'scoreRule',
        path: '/scoreRule',
        component: () => import('@/views/ScoreRule')
      },
      {
        name: 'billTerm',
        path: '/billTerm',
        component: () => import('@/views/BillTerm')
      },
      {
        name: 'me',
        path: '/me',
        meta: { auth: true },
        component: () => import('@/views/Me')
      },
      {
        name: 'editInfo',
        path: '/me/edit',
        meta: { auth: true },
        component: () => import('@/views/EditInfo')
      },
      {
        name: 'storeHome',
        path: '/store/:id',
        component: () => import('@/views/Stores')
      },
      {
        name: 'storeCoupon',
        path: '/store/coupon/:id',
        component: () => import('@/views/Stores/coupon')
      },
      {
        name: 'couponDetail',
        path: '/coupon/detail/:id',
        component: () => import('@/views/Coupon/detail')
      },
      {
        name: 'sales',
        path: '/order/maintain/:id',
        component: () => import('@/views/MyOrder/Sales/index')
      },
      {
        name: 'changeEmail',
        path: '/changeEmail',
        meta: { auth: true },
        component: () => import('@/views/ChangeEmail')
      },
      {
        name: 'ChangePhone',
        path: '/ChangePhone',
        meta: { auth: true },
        component: () => import('@/views/ChangePhone')
      },
      {
        name: 'orderTerm',
        path: '/orderTerm',
        component: () => import('@/views/OrderTermDetailRules')
      }
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { y: 0 }
  },
  mode: 'history'
});

router.beforeEach((to, _, next) => {
  const { name, meta } = to;
  const { token } = store.getters;
  // 如果已经登录并且跳转到登录页，那么则跳转到首页
  if (name === 'login' && token) {
    return next({ name: 'home' });
  }
  // 如果需要校验，并且没有token那么就先跳转到登录页
  if (meta.auth && !token) {
    return next({
      name: 'login', query: {
        redirect: to.name
      }
    });
  }
  next();
});

export default router
